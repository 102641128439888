<template>
  <div v-if="isLoading">
    <div class="text-center text-danger screen-center">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else>
    <div class="breadcrumb">
      <h1>Modificar Paciente <span class="h4 pl-2"> N°: {{correlativo}}</span></h1>
    </div>
    <div class="row mb-2">
      <div class="col-sm-12">
        <div class="card text-left">
          <form @submit.prevent="guardarPaciente">
            <div class="card-header">
              <div class="col-sm-5">
                <button type="submit"
                 class="btn bg-custom-green mr-2 rounded"
                 :disabled="buttonDisabled">
                 Guardar
                </button>
                <router-link :to="{ name: 'paciente-index' }">
                  <button class="btn btn-white border border-light rounded">
                    Regresar
                  </button>
                </router-link>
              </div>
            </div>
            <div class="card-body">
              <div class="row mb-2">
                <div class="col-sm-12 py-3 px-2">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="row mb-2">
                        <div class="col-sm-2 pr-0 text-right">
                          <label class="col-form-label">Nombres:
                            <span class="text-danger">*</span>
                          </label>
                        </div>
                        <div class="col-sm-7">
                          <input type="text"
                          v-model="nombres"
                          class="form-control"
                          autocomplete="off">
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-2 pr-0 text-right">
                          <label class="col-form-label">Apellidos:
                            <span class="text-danger">*</span>
                          </label>
                        </div>
                        <div class="col-sm-7">
                          <input type="text"
                          v-model="apellidos"
                          class="form-control"
                          autocomplete="off">
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-2 pr-0 text-right">
                          <label class="col-form-label">Email:</label>
                        </div>
                        <div class="col-sm-7">
                          <input type="email"
                          v-model="email"
                          class="form-control"
                          autocomplete="off">
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="row mb-2">
                        <div class="col-sm-1"></div>
                        <div class="col-sm-3 text-right">
                          <label class="col-form-label">Fecha de Nacimiento:</label>
                        </div>
                        <div class="col-sm-3 pr-0">
                          <date-picker
                            class="col-md-12 px-0"
                            v-model="fechaNacimiento"
                            lang="es"
                            format="DD/MM/YYYY"
                            type="date"
                            value-type="date"
                            @input="getAge(fechaNacimiento)"
                          ></date-picker>
                        </div>
                        <div class="col-sm-2 text-right">
                          <label class="col-form-label">{{edadActual}}</label>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-2"></div>
                        <div class="col-sm-2 pr-0 text-right">
                          <label class="col-form-label">Nacionalidad:</label>
                        </div>
                        <div class="col-sm-3 pr-0">
                          <b-form-select v-model="nacionalidadSelected" :options="paises">
                          </b-form-select>
                        </div>
                        <div class="col-sm-3">
                          <b-form-select v-model="generoSelected" :options="genero">
                          </b-form-select>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-4 pr-0 text-right">
                          <div class="row">
                            <div class="col-sm-7 pr-0">
                              <label class="col-form-label">Celular:
                                <span class="text-danger">*</span>
                              </label>
                            </div>
                            <div class="col-sm-5 px-2">
                              <multiselect v-model="paisSelected" :options="paisesOptions"
                              :allow-empty="false" :show-labels="false" :searchable="false"
                              :close-on-select="true" label="descripcion" track-by="descripcion"
                              @input="setCodigoPais">
                                <template slot="singleLabel" slot-scope="props">
                                  <img class="option__image"
                                    :src="props.option.img"
                                    :alt="props.option.descripcion"
                                    width="26px">
                                </template>
                                <template slot="option" slot-scope="props">
                                  <img class="option__image"
                                    :src="props.option.img"
                                    :alt="props.option.descripcion"
                                    width="26px">
                                </template>
                              </multiselect>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3 pr-0 pt-1">
                          <input type="text"
                            class="form-control"
                            :placeholder="'+'+codigoPais"
                            v-model="celular"
                            autocomplete="off"
                            @keydown="validarCaracter"
                            @blur="reValidarCelular">
                        </div>
                        <div class="col-sm-3  pt-1">
                          <input type="text"
                          v-model="numeroCarnet"
                          class="form-control text-center"
                          autocomplete="off"
                          placeholder="C.I.">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="row mb-2">
                        <div class="col-sm-2 pr-0 text-right">
                          <label class="col-form-label">Direccion:</label>
                        </div>
                        <div class="col-sm-7">
                          <input type="text"
                            v-model="direccion"
                            class="form-control"
                            autocomplete="off"
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-2 pr-0 pl-2 text-right">
                          <label class="col-form-label">Tipo de Seguro:</label>
                        </div>
                        <div class="col-sm-7">
                          <multiselect
                            id="comboTipoSeguro"
                            v-model="tipoSeguroSelected"
                            :allow-empty="false"
                            :options="tipoSeguroList"
                            placeholder=""
                            label="nombre"
                            track-by="id"
                            select-label=""
                            deselect-label="X"
                          >
                            <span slot="caret" v-if="!(tipoSeguroSelected===null)"></span>
                            <span slot="noResult">Sin Resultados</span>
                            <span slot="noOptions">Lista Vacia</span>
                          </multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="row mb-2">
                        <div class="col-sm-1"></div>
                        <div class="col-sm-3 pr-0 text-right">
                          <label class="col-form-label">Medico Externo:</label>
                        </div>
                        <div class="col-sm-6">
                          <multiselect v-model="medicoExternoSelected"
                          :options="medicosExternos"
                          placeholder=""
                          label="nombre" track-by="id"
                          select-label="" deselect-label="X">
                            <span slot="caret" v-if="!(medicoExternoSelected===null)"></span>
                            <span slot="noResult">Sin Resultados</span>
                          </multiselect>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-1"></div>
                        <div class="col-sm-3 pr-0 text-right">
                          <label class="col-form-label">Clinica:</label>
                        </div>
                        <div class="col-sm-6">
                          <multiselect v-model="clinicaExternaSelected"
                          :options="clinicasExternas"
                          placeholder=""
                          label="nombre" track-by="id"
                          select-label="" deselect-label="X">
                            <span slot="caret" v-if="!(clinicaExternaSelected===null)"></span>
                            <span slot="noResult">Sin Resultados</span>
                          </multiselect>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-sm-12 th-custom-color rounded-box pt-2">
                  <ul class="nav">
                    <li>
                      <a class="nav-link text-dark h5" href="javascript:void(0)"
                      @click="componentShow = 'DatosFactura'"
                      :class="componentShow==='DatosFactura' ? 'bg-white rounded-box': ''">
                      Datos de Factura
                      </a>
                    </li>
                    <li v-if="isDisableSeguro">
                      <a class="nav-link text-dark h5" href="javascript:void(0)"
                        @click="componentShow = 'SeguroMedico'"
                        :class="componentShow==='SeguroMedico' ? 'bg-white rounded-box': ''">
                        Seguro Medico
                      </a>
                    </li>
                    <li>
                      <a class="nav-link text-dark h5" href="javascript:void(0)"
                        @click="componentShow = 'Contacto'"
                        :class="componentShow==='Contacto' ? 'bg-white rounded-box': ''">
                        Contacto
                      </a>
                    </li>
                    <li>
                      <a class="nav-link text-dark h5" href="javascript:void(0)"
                        @click="componentShow = 'Familiar'"
                        :class="componentShow==='Familiar' ? 'bg-white rounded-box': ''">
                        Familiar
                      </a>
                    </li>
                    <li>
                      <a class="nav-link text-dark h5" href="javascript:void(0)"
                        @click="componentShow = 'MedicoCabecera'"
                        :class="componentShow==='MedicoCabecera' ? 'bg-white rounded-box': ''">
                        Médico de Cabecera
                      </a>
                    </li>
                    <!-- <li>
                      <a class="nav-link text-dark h5" href="javascript:void(0)"
                        @click="componentShow = 'CoberturaSeguro'"
                        :class="componentShow==='CoberturaSeguro' ? 'bg-white rounded-box': ''">
                        Cobertura del Seguro
                      </a>
                    </li> -->
                  </ul>
                </div>
              </div>
              <!-- Componente que se comporta como una fila -->
              <keep-alive>
              <component :is="componentShow"></component>
              </keep-alive>
              <!-- Fin Componente dinamico -->
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store/index';
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import moment from 'moment';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import DatosFactura from '../components/TheDatosFactura.vue';
import SeguroMedico from '../components/TheSeguroMedico.vue';
import Contacto from '../components/TheContacto.vue';
import Familiar from '../components/TheFamiliar.vue';
import MedicoCabecera from '../components/TheMedicoCabecera.vue';
import CoberturaSeguro from '../components/TheCoberturaSeguro.vue';
import axios from '../../common/axiosERP';
import { util } from '../../../plugins/util';

export default {
  name: 'edit',
  components: {
    Multiselect,
    DatePicker,
    DatosFactura,
    SeguroMedico,
    Contacto,
    Familiar,
    MedicoCabecera,
    CoberturaSeguro,
  },
  async created() {
    await this.cargarDatosIniciales();
    this.getAge(this.fechaNacimiento);
  },
  beforeDestroy() {
    this.setlistaDatosFactura([]);
    this.setListaSeguros([]);
    this.setlistaContactos([]);
    this.setlistaFamiliares([]);
    this.setlistaMedicosCabecera([]);
    this.setlistaCoberturaSeguro([]);
    this.setTiposFamiliares([]);
    this.resetState();
  },
  data() {
    return {
      isLoading: true,
      id: atob(this.$route.params.id),
      correlativo: '',
      nombres: '',
      apellidos: '',
      email: '',
      numeroCarnet: '',
      direccion: '',
      tipoSeguroList: [],
      fechaNacimiento: new Date(),
      edadActual: null,
      nacionalidadSelected: 1,
      generoSelected: 2,
      componentShow: 'DatosFactura',
      codigoPais: '',
      celular: '',
      medicosExternos: [],
      medicoExternoSelected: null,
      clinicasExternas: [],
      clinicaExternaSelected: null,
      paisSelected: { descripcion: 'Bo', codigo_telefono: '591', img: 'https://www.countryflags.io/BO/shiny/64.png' },
      paisesOptions: [],
      paises: [],
      genero: [],
      buttonDisabled: false,
      isDisableSeguro: store.getters['main/hasPermisionListarSeguro'],
    };
  },
  methods:
  {
    async cargarDatosIniciales() {
      this.isLoading = true;
      try {
        const response = await axios.get(`/clinic/patient/${this.id}/edit`);
        const { numero } = response.data.data.paciente;
        this.nombres = response.data.data.paciente.nombres;
        this.apellidos = response.data.data.paciente.apellidos;
        this.email = response.data.data.paciente.mail;
        this.fechaNacimiento = new Date(moment(response.data.data.paciente.fecha_nacimiento));
        const nacionId = response.data.data.paciente.nacionalidad_id;
        const { sexo } = response.data.data;
        const { paises } = response.data.data;
        const paisID = response.data.data.paciente.pais_id;
        const generoId = response.data.data.paciente.sexo_id;
        this.celular = response.data.data.paciente.telefono;
        this.numeroCarnet = response.data.data.paciente.ci;
        this.direccion = response.data.data.paciente.direccion;
        const { aseguradoras } = response.data.data;
        const tipoContactos = response.data.data.tipo_contactos;
        const { parentescos } = response.data.data;
        const { pacientes } = response.data.data;
        const { medicos } = response.data.data;
        const tipoCoberturas = response.data.data.tipo_cobertura_seguros;
        const { monedas } = response.data.data;
        const { situaciones } = response.data.data;
        const listaDatosFactura = response.data.data.dato_facturas;
        const listaSeguroMedico = response.data.data.seguro_medicos;
        const listaContacto = response.data.data.contacto_pacientes;
        const listaFamiliar = response.data.data.familiares;
        const listaMedicoCabecera = response.data.data.medico_cabeceras;
        const listaCoberturaSeguro = response.data.data.cobertura_seguros;
        const MEDICOS_EXTERNOS = response.data.data.medico_externos;
        const CLINICAS_EXTERNAS = response.data.data.clinica_externos;
        const MEDICO_EXTERNO_ID = response.data.data.paciente.medico_externo_id;
        const CLINICA_EXTERNA_ID = response.data.data.paciente.clinica_externo_id;
        const TIPO_SEGURO_ID = response.data.data.paciente.tipo_seguro_id;
        const LISTA_TIPO_FAMILIARES = response.data.data.tipo_familiars;
        this.tipoSeguroList = response.data.data.tipo_seguros;
        this.cargarCorrelativo(numero);
        this.cargarPaises(paises);
        this.cargarGeneros(sexo);
        this.seleccionarNacionalidad(nacionId);
        this.seleccionarGenero(generoId);
        this.seleccionarPais(paisID);
        this.cargarMedicosExternos(MEDICOS_EXTERNOS);
        this.cargarClinicasExternas(CLINICAS_EXTERNAS);
        this.cargarMedicoExternoSelected(MEDICO_EXTERNO_ID);
        this.cargarClinicaExternaSelected(CLINICA_EXTERNA_ID);
        this.cargarTipoSeguroSelected(TIPO_SEGURO_ID);
        this.cargarAseguradoras(aseguradoras);
        this.cargarTipoContactos(tipoContactos);
        this.cargarParentescos(parentescos);
        this.cargarPacientes(pacientes);
        this.cargarMedicos(medicos);
        this.cargarTipoCoberturas(tipoCoberturas);
        this.cargarTipoMonedas(monedas);
        this.cargarSituaciones(situaciones);
        this.setTiposFamiliares(LISTA_TIPO_FAMILIARES);
        // Secciones de la interfaz paciente
        this.cargarlistaDatosFactura(listaDatosFactura);
        this.cargarlistaSeguroMedico(listaSeguroMedico, aseguradoras, LISTA_TIPO_FAMILIARES);
        this.cargarlistaContacto(listaContacto, tipoContactos, parentescos, paises);
        this.cargarlistaFamiliar(listaFamiliar, aseguradoras, LISTA_TIPO_FAMILIARES);
        this.cargarlistaMedicoCabecera(listaMedicoCabecera);
        this.cargarlistaCoberturaSeguro(listaCoberturaSeguro, monedas);
        this.setTypeDocuments(response.data.data.type_documents);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    guardarPaciente() {
      const request = {
        paciente: {
          id: this.id,
          nombres: this.nombres,
          apellidos: this.apellidos,
          mail: this.email,
          fecha_nacimiento: moment(this.fechaNacimiento).format('YYYY-MM-DD'),
          nacionalidad_id: this.nacionalidadSelected,
          pais_id: this.paisSelected.id,
          telefono: this.celular,
          sexo_id: this.generoSelected,
          ci: this.numeroCarnet,
          direccion: this.direccion,
          medico_externo_id: this.obtenerMedicoExternoSelected(),
          clinica_externo_id: this.obtenerClinicaExternaSelected(),
          tipo_seguro_id: this.tipoSeguroSelected.id,
          estado: 'true',
        },
        dato_facturas: this.getlistaDatosFactura,
        seguro_medicos: this.getlistaSeguros,
        contacto_pacientes: this.getlistaContactos,
        familiares: this.getlistaFamiliares,
        medico_cabeceras: this.getlistaMedicosCabecera,
        cobertura_seguros: this.getlistaCoberturaSeguro,
      };
      this.callApiUpdate(request);
    },
    async callApiUpdate(request) {
      this.buttonDisabled = true;
      try {
        const response = await axios.put(`/clinic/patient/${this.id}`, request);
        util.showNotify(response.data.message, 'success');
        this.redirect({ name: 'paciente-index' });
      } catch (error) {
        const FALLO_VALIDACION = 422;
        if (error.response.status !== FALLO_VALIDACION) {
          util.showNotify(error.response.data.message, 'error');
        } else {
          Object.entries(error.response.data.data).forEach(([, mensajes]) => {
            mensajes.forEach((texto) => util.showNotify(texto, 'error'));
          });
        }
        this.buttonDisabled = false;
      }
    },
    redirect(path) {
      this.$router.push(path);
    },
    cargarCorrelativo(nro) {
      this.correlativo = nro;
    },
    cargarPaises(lista) {
      lista.forEach((item) => {
        const obj = {
          value: item.id,
          text: item.descripcion,
        };
        this.paises.push(obj);
      });
      this.paisesOptions = lista;
      this.setPaises(lista);
    },
    cargarGeneros(lista) {
      lista.forEach((item) => {
        const obj = {
          value: item.id,
          text: item.nombre,
          sigla: item.sigla,
        };
        this.genero.push(obj);
      });
    },
    seleccionarNacionalidad(id) {
      this.nacionalidadSelected = id;
    },
    seleccionarGenero(id) {
      this.generoSelected = id;
    },
    seleccionarPais(paisId) {
      const objetoPais = this.paisesOptions.filter((el) => el.id === paisId)[0];
      this.paisSelected = objetoPais;
      this.codigoPais = objetoPais.codigo_telefono;
    },
    setCodigoPais() {
      this.codigoPais = this.paisSelected.codigo_telefono;
    },
    cargarMedicosExternos(lista) {
      this.medicosExternos = lista;
    },
    cargarClinicasExternas(lista) {
      this.clinicasExternas = lista;
    },
    cargarMedicoExternoSelected(id) {
      if (id === null) {
        return;
      }
      this.medicoExternoSelected = this.medicosExternos.find((el) => el.id === id);
    },
    cargarClinicaExternaSelected(id) {
      if (id === null) {
        return;
      }
      this.clinicaExternaSelected = this.clinicasExternas.find((el) => el.id === id);
    },
    cargarAseguradoras(lista) {
      this.setAseguradoras(lista);
    },
    cargarTipoContactos(lista) {
      const tipos = [];
      lista.forEach((item) => {
        const obj = {
          value: item.id,
          text: item.nombre,
        };
        tipos.push(obj);
      });
      this.setTipoContactos(tipos);
    },
    cargarParentescos(lista) {
      const parentescos = [];
      lista.forEach((item) => {
        const obj = {
          value: item.id,
          text: item.nombre,
        };
        parentescos.push(obj);
      });
      this.setParentescos(parentescos);
    },
    cargarPacientes(lista) {
      this.setPacientes(lista);
    },
    cargarMedicos(lista) {
      this.setMedicos(lista);
    },
    cargarTipoCoberturas(lista) {
      const coberturas = [];
      lista.forEach((item) => {
        const obj = {
          value: item.id,
          text: item.nombre,
        };
        coberturas.push(obj);
      });
      this.setTipoCoberturas(coberturas);
    },
    cargarTipoMonedas(lista) {
      const monedas = [];
      lista.forEach((item) => {
        const obj = {
          value: item.id,
          text: item.name,
          is_principal: item.is_principal,
          simbolo: item.simbolo,
          codigo_iso: item.codigo_iso,
        };
        monedas.push(obj);
      });
      this.setMonedas(monedas);
    },
    cargarSituaciones(lista) {
      this.setSituaciones(lista);
    },
    cargarlistaDatosFactura(lista) {
      const listaAuxiliar = [];
      lista.forEach((item, index) => {
        const obj = {
          numero: index + 1,
          id: item.id,
          razon_social: item.razon_social,
          nit: item.nit,
          type_document_id: item.type_document_id,
          type_document: item.type_document,
          complement: item.complement,
          estado: item.estado,
        };
        listaAuxiliar.push(obj);
      });
      this.setlistaDatosFactura(listaAuxiliar);
    },
    cargarlistaSeguroMedico(lista, listaAseguradora, listaTipoFamiliares) {
      const listaAuxiliar = [];
      let TIPO_FAMILIAR_NOMBRE = null;
      lista.forEach((item, index) => {
        const aseguradora = listaAseguradora.filter((elemento) => (
          elemento.id === item.aseguradora_id
        ))[0].nombre;
        if (item.tipo_familiar_id) {
          TIPO_FAMILIAR_NOMBRE = listaTipoFamiliares.filter((el) => (
            el.id === item.tipo_familiar_id
          ))[0].nombre;
        }
        const obj = {
          numero: index + 1,
          id: item.id,
          aseguradora_id: item.aseguradora_id,
          aseguradora,
          numero_poliza: item.numero_poliza,
          codigo_aseguradora: item.codigo_aseguradora,
          fecha_venc: item.fecha_vencimiento,
          fecha_vencimiento: moment(item.fecha_vencimiento, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          tipo_familiar_id: item.tipo_familiar_id,
          tipo_familiar_nombre: TIPO_FAMILIAR_NOMBRE,
          cantidad: item.cantidad,
          estado: item.estado,
        };
        listaAuxiliar.push(obj);
      });
      this.setListaSeguros(listaAuxiliar);
    },
    cargarlistaContacto(lista, tipoContactos, listaParentescos, listaPaises) {
      const listaAuxiliar = [];
      lista.forEach((item, index) => {
        const tipo = tipoContactos.filter((elemento) => (
          elemento.id === item.tipo_contacto_id
        ))[0].nombre;
        const parentesco = listaParentescos.filter((elemento) => (
          elemento.id === item.parentesco_id
        ))[0].nombre;
        const codigoPais = listaPaises.filter((elemento) => (
          elemento.id === item.pais_id
        ))[0].codigo_telefono;
        const obj = {
          numero: index + 1,
          id: item.id,
          tipo_contacto_id: item.tipo_contacto_id,
          tipo,
          parentesco_id: item.parentesco_id,
          parentesco,
          nombres: item.nombres,
          pais_id: item.pais_id,
          telefono_completo: `+${codigoPais} ${item.telefono}`,
          telefono: item.telefono,
          mail: item.mail,
          estado: item.estado,
        };
        listaAuxiliar.push(obj);
      });
      this.setlistaContactos(listaAuxiliar);
    },
    cargarlistaFamiliar(lista, listaAseguradora, listaTipoFamiliares) {
      const listaAuxiliar = [];
      lista.forEach((item, index) => {
        const ASEGURADORA_NOMBRE = listaAseguradora.filter((elemento) => (
          elemento.id === item.seguro_id
        ))[0].nombre;
        const TIPO_FAMILIAR_NOMBRE = listaTipoFamiliares.filter((el) => (
          el.id === item.tipo_familiar_id
        ))[0].nombre;

        const obj = {
          numero: index + 1,
          id: item.id,
          celular: item.celular,
          edad: item.edad,
          email: item.email,
          nombre: item.full_name,
          numero_exp: item.numero,
          paciente_familiar_id: item.paciente_familiar_id,
          parentesco: item.parentesco,
          parentesco_id: item.parentesco_id,
          seguro_id: item.seguro_id,
          seguro_name: ASEGURADORA_NOMBRE,
          cod_seguro: item.cod_seguro,
          tipo_familiar_id: item.tipo_familiar_id,
          tipo_familiar_nombre: TIPO_FAMILIAR_NOMBRE,
          estado: item.estado,
        };
        listaAuxiliar.push(obj);
      });
      this.setlistaFamiliares(listaAuxiliar);
    },
    cargarlistaMedicoCabecera(lista) {
      const listaAuxiliar = [];
      lista.forEach((item, index) => {
        const obj = {
          numero: index + 1,
          id: item.id,
          nombre: item.medico,
          medico_id: item.medico_id,
          medico_numero: item.numero,
          especialidad: item.especialidad,
          situacion_id: item.situacion_id,
          estado_local: item.situacion_nombre,
          estado: item.estado,
        };
        listaAuxiliar.push(obj);
      });
      this.setlistaMedicosCabecera(listaAuxiliar);
    },
    cargarlistaCoberturaSeguro(lista, monedas) {
      const listaAuxiliar = [];
      lista.forEach((item, index) => {
        const { simbolo } = monedas.filter((elemento) => (
          elemento.id === item.moneda_id
        ))[0];
        const unidad = (item.tipo_cobertura_seguro_id === 1) ? '%' : simbolo;
        const obj = {
          numero: index + 1,
          id: item.id,
          aseguradora_id: item.aseguradora_id,
          aseguradora_numero: item.numero,
          aseguradora: item.aseguradora,
          moneda_id: item.moneda_id,
          monto: item.monto,
          cobertura: `${parseFloat(item.monto)} ${unidad}`,
          servicio_id: item.servicio_id,
          servicio: item.servicio,
          situacion_id: item.situacion_id,
          estado_local: item.situacion_nombre,
          tipo_cobertura_seguro_id: item.tipo_cobertura_seguro_id,
          estado: item.estado,
        };
        listaAuxiliar.push(obj);
      });
      this.setlistaCoberturaSeguro(listaAuxiliar);
    },
    cargarTipoSeguroSelected(id) {
      if (id === null) {
        return;
      }
      this.tipoSeguroSelected = this.tipoSeguroList.find((el) => el.id === id);
    },
    getAge(dateString) {
      if (dateString === null) {
        this.edadActual = '';
        return;
      }
      const today = new Date();
      const birthDate = new Date(dateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age -= 1;
      }
      this.edadActual = `${age} Años`;
    },
    validarCaracter(event) {
      const validKeys = new RegExp('^[0-9]*$');
      const controlKeys = ['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight', 'KeyX', 'KeyC', 'KeyV', 'Home', 'End', 'Tab'];
      if (controlKeys.includes(event.code)) {
        switch (event.code) {
          case 'KeyX':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          case 'KeyC':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          case 'KeyV':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          default:
            break;
        }
        return;
      }
      if (!validKeys.test(event.key)) {
        event.preventDefault();
      }
    },
    reValidarCelular() {
      if (this.celular === '') {
        return;
      }
      const validKeys = new RegExp('^[0-9]*$');
      if (!validKeys.test(this.celular)) {
        this.celular = '';
      }
    },
    obtenerMedicoExternoSelected() {
      if (this.medicoExternoSelected === null) {
        return null;
      }
      return this.medicoExternoSelected.id;
    },
    obtenerClinicaExternaSelected() {
      if (this.clinicaExternaSelected === null) {
        return null;
      }
      return this.clinicaExternaSelected.id;
    },
    resetState() {
      this.resetMainFormState();
    },
    ...mapMutations('aseguradoras', ['setAseguradoras']),
    ...mapMutations('datosFactura', ['setlistaDatosFactura']),
    ...mapMutations('seguroMedico', ['setListaSeguros']),
    ...mapMutations('contacto', ['setTipoContactos', 'setPaises', 'setlistaContactos']),
    ...mapMutations('parentescos', ['setParentescos']),
    ...mapMutations('familiar', ['setPacientes', 'setlistaFamiliares']),
    ...mapMutations('medicoCabecera', ['setMedicos', 'setlistaMedicosCabecera']),
    ...mapMutations('coberturaSeguro', ['setTipoCoberturas', 'setMonedas', 'setlistaCoberturaSeguro']),
    ...mapMutations('situaciones', ['setSituaciones']),
    ...mapMutations('datosFactura', ['setTypeDocuments']),
    ...mapMutations('tipoFamiliares', ['setTiposFamiliares']),
    ...mapActions('mainForm', [
      'resetMainFormState',
    ]),
  },
  computed: {
    ...mapFields('mainForm', [
      'tipoSeguroSelected',
    ]),
    ...mapGetters('datosFactura', ['getlistaDatosFactura']),
    ...mapGetters('seguroMedico', ['getlistaSeguros']),
    ...mapGetters('contacto', ['getlistaContactos']),
    ...mapGetters('familiar', ['getlistaFamiliares']),
    ...mapGetters('medicoCabecera', ['getlistaMedicosCabecera']),
    ...mapGetters('coberturaSeguro', ['getlistaCoberturaSeguro']),
  },
};
</script>

<style scoped>
.btn-skyblue
{
  color: #fff;
  background-color: #61c6f2;
}
.bg-skyblue
{
  background: #61c6f2 !important;
}
.rounded-box
{
  border-radius: 15px 15px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
